/* 
 This function will be called in the event when browser breakpoint changes
 */

var public_vars = public_vars || {};

jQuery.extend(public_vars, {

    breakpoints: {
        largescreen: 	[991, -1],
        tabletscreen: 	[768, 990],
        devicescreen: 	[420, 767],
        sdevicescreen:	[0, 419]
    },

    lastBreakpoint: null
});

function hide_sidebar_menu(with_animation)
        {
            if(isxs()){return;}

            if(public_vars.isRightSidebar)
            {
                rb_hide_sidebar_menu(with_animation);
                return;
            }

            if( ! with_animation)
            {
                public_vars.$pageContainer.addClass(public_vars.sidebarCollapseClass);
            }
            else
            {
                if(public_vars.$mainMenu.data('is-busy') || public_vars.$pageContainer.hasClass(public_vars.sidebarCollapseClass))
                {return;}

                fit_main_content_height();

                var current_padding = parseInt(public_vars.$pageContainer.css('padding-left'), 10);

                // Check
                public_vars.$pageContainer.addClass(public_vars.sidebarCollapseClass);

                var padding_left     = parseInt(public_vars.$pageContainer.css('padding-left'), 10),
                    $span_elements   = public_vars.$mainMenu.find('li a span'),
                    $submenus        = public_vars.$mainMenu.find('.has-sub > ul'),
                    $search_input    = public_vars.$mainMenu.find('#search .search-input'),
                    $search_button   = public_vars.$mainMenu.find('#search button'),
                    $logo_env        = public_vars.$sidebarMenu.find('.logo-env'),
                    $collapse_icon   = $logo_env.find('.sidebar-collapse'),
                    $logo            = $logo_env.find('.logo'),
                    $sidebar_ulink   = public_vars.$sidebarUser.find('span, strong'),

                    logo_env_padding = parseInt($logo_env.css('padding'), 10);


                // Return to normal state
                public_vars.$pageContainer.removeClass(public_vars.sidebarCollapseClass);

                var padding_diff = current_padding - padding_left;

                // Start animation (1)
                public_vars.$mainMenu.data('is-busy', true);


                // Add Classes & Hide Span Elements
                public_vars.$pageContainer.addClass(public_vars.sidebarOnTransitionClass);
                setTimeout(function(){ public_vars.$pageContainer.addClass(public_vars.sidebarOnHideTransitionClass); }, 1);

                TweenMax.to($submenus, public_vars.sidebarTransitionTime / 1100, {css: {height: 0}});

                //TMP$search_input.transit({opacity: 0}, public_vars.sidebarTransitionTime);
                $search_button.transit({right: padding_diff}, public_vars.sidebarTransitionTime);
                $logo.transit({scale: [1,0], perspective: 300/*, opacity: 0*/}, public_vars.sidebarTransitionTime/2);
                $logo_env.transit({padding: logo_env_padding}, public_vars.sidebarTransitionTime);
                //$collapse_icon.transit({left: -padding_diff+3}, public_vars.sidebarTransitionTime * 5);

                if( ! rtl())
                {
                    TweenMax.to($collapse_icon, 0.5, {css: {left: -padding_diff+3}, delay: 0.1});
                }

                public_vars.$pageContainer.transit({paddingLeft: padding_left}, public_vars.sidebarTransitionTime);

                TweenMax.set($sidebar_ulink, {css: {opacity: 0}});


                setTimeout(function()
                {
                    // In the end do some stuff
                    public_vars.$pageContainer
                        .add(public_vars.$sidebarMenu)
                        .add($search_input)
                        .add($search_button)
                        .add($logo_env)
                        .add($logo)
                        .add($span_elements)
                        .add($collapse_icon)
                        .add($submenus)
                        .add($sidebar_ulink)
                        .add(public_vars.$mainMenu)
                        .attr('style', '');

                    public_vars.$pageContainer.addClass(public_vars.sidebarCollapseClass);

                    public_vars.$mainMenu.data('is-busy', false);
                    public_vars.$pageContainer.removeClass(public_vars.sidebarOnTransitionClass).removeClass(public_vars.sidebarOnHideTransitionClass);

                    fit_main_content_height();

                }, public_vars.sidebarTransitionTime);
            }
        }
/* Main Function that will be called each time when the screen breakpoint changes */
function resizable(breakpoint)
{
    var sb_with_animation;


    // Large Screen Specific Script
    if(is('largescreen'))
    {
        sb_with_animation = public_vars.$sidebarMenu.find(".sidebar-collapse-icon").hasClass('with-animation') || public_vars.$sidebarMenu.hasClass('with-animation');

        if(public_vars.$sidebarMenu.data('initial-state') == 'open')
        {
            show_sidebar_menu(sb_with_animation);
        }
        else
        {
            hide_sidebar_menu(sb_with_animation);
        }
    }


    // Tablet or larger screen
    if(ismdxl())
    {
        public_vars.$mainMenu.attr('style', '');
    }


    // Tablet Screen Specific Script
    if(is('tabletscreen'))
    {
        sb_with_animation = public_vars.$sidebarMenu.find(".sidebar-collapse-icon").hasClass('with-animation') || public_vars.$sidebarMenu.hasClass('with-animation');

        hide_sidebar_menu(sb_with_animation);
    }


    // Tablet Screen Specific Script
    if(isxs())
    {
        public_vars.$pageContainer.removeClass('sidebar-collapsed');
    }


    // Trigger Event
    jQuery(window).trigger('neon.resize');


    // Fit main content height
    fit_main_content_height();
}



/* Functions */

// Get current breakpoint
function get_current_breakpoint()
{
    var width = jQuery(window).width(),
        breakpoints = public_vars.breakpoints;

    for(var breakpont_label in breakpoints)
    {
        var bp_arr = breakpoints[breakpont_label],
            min = bp_arr[0],
            max = bp_arr[1];

        if(max == -1)
            max = width;

        if(min <= width && max >= width)
        {
            return breakpont_label;
        }
    }

    return null;
}


// Check current screen breakpoint
function is(screen_label)
{
    return get_current_breakpoint() == screen_label;
}


// Is xs device
function isxs()
{
    return is('devicescreen') || is('sdevicescreen');
}

// Is md or xl
function ismdxl()
{
    return is('tabletscreen') || is('largescreen');
}


// Trigger Resizable Function
function trigger_resizable()
{
    if(public_vars.lastBreakpoint != get_current_breakpoint())
    {
        public_vars.lastBreakpoint = get_current_breakpoint();
        resizable(public_vars.lastBreakpoint);
    }
}